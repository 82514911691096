@import '../../../styles/_variables.scss';


.text-shadow-none {
    text-shadow: none;
}


.navlink-item {

    &:hover, :focus, :active {
        background-color: var(--alternative);
    }
    
    &.active {
        background-color: var(--alternative);
    }
}


#main-logo {
    line-height: 0.5;
    font-size: 2rem;
    font-weight: 700;
    color: var(--logo-color);
}

.navlink-item {
    display: block !important;
    text-decoration: none !important;

    border-radius: 0.25rem !important;
    padding: 0.5rem !important;

    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out !important;

    color: var(--majority-contrast) !important;

    &:hover, &:focus {
        background-color: var(--alternative);
    }
}
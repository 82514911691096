$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer / 4,
  2: $spacer / 2,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3, //custom from 6 on
  6: $spacer * 5,
  7: $spacer * 6,
  8: $spacer * 8,
  9: $spacer * 9,
) !default;

$utilities: (
  "width": (
    property: width,
    responsive: true,
    class: w,
    values: (
      25: 25%,
      50: 50%,
      75: 75%,
      100: 100%,
      auto: auto
    )
  ),
  "top": (
    property: top,
    responsive: true,
    class: top,
    values: (
      0: 0%,
      95: 95%,
      100: 100%,
    )
  ),
  "sticky-top": (
    property: top,
    responsive: true,
    class: sticky-top,
    values: (
      10: 10px,
      30: 30px,
      120: 120px,
    )
  ),
  "min-height": (
    responsive: true,
    property: min-height,
    class: min-h,
    values: $spacers
  ),
  "viewport-height": (
    property: height,
    class: vh,
    values: (
      100: 100vh,
      90: 90vh,
      75: 75vh,
      70: 70vh,
      50: 50vh,
      25: 25vh
    )
  ),
  "viewport-max-height": (
    property: max-height,
    class: vmh,
    values: (
      100: 100vh,
      95: 95vh,
      85: 85vh,
      75: 75vh,
      50: 50vh,
      25: 25vh
    )
  ),
  "viewport-max-width": (
    property: max-width,
    class: vmw,
    values: (
      100: 100vw,
      75: 75vw,
      50: 50vw,
      25: 25vw
    )
  ),
  "max-width": (
    property: max-width,
    class: mw,
    values: (
      85: 85%,
      100: 100%
    )
  ),
  "resizable": (
    property: resize,
    class: resizable,
    values: (
      none: none,
      vertical: vertical
    )
  ),
  "white-space": (
    responsive: true,
    property: white-space,
    class: text,
    values: (
      wrap: normal,
      nowrap: nowrap,
    )
  ),
  "position": (
    responsive: true,
    property: position,
    values: static relative absolute fixed sticky
  ),
  "opacity": (
    property: opacity,
    values: (
      0: 0,
      25: .25,
      50: .5,
      75: .75,
      100: 1,
    )
  )
);

// colors
$primary: #ffd04b; // this is not actual primary colour, but it controls bootstrap form background so I made it a bit darker.

// font sizes
$font-size-base: 1rem;

$h1-font-size:                $font-size-base * 2.25;
$h2-font-size:                $font-size-base * 1.6;
$h3-font-size:                $font-size-base * 1.25;
$h4-font-size:                $font-size-base * 1.1;
$h5-font-size:                $font-size-base;
$h6-font-size:                $font-size-base;

$small-font-size: 0.75rem;
// sizes

$border-radius-lg: 1.75rem; // to use: border-3
@import './variables';
// bootstrap but cropped
//
// Base styles
//
  
//
// Button Sizes
//

.btn-lg {
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
}

.btn-sm {
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
}

// end bootstrap


.btn-hover {
    transition: all 0.2s ease-out 0s;

    &:hover, &:focus {
        // transform: scale(1.02);
        box-shadow: 0 -0.1rem 1rem rgba(0, 0, 0, 0.15) !important;
        // color: rgb(255, 255, 255)

    }
}


.btn-hover-pop {
    transition: all 0.2s ease-out 0s;

    &:hover, &:focus {
        transform: scale(1.02);
        box-shadow: 0 -0.1rem 1rem rgba(0, 0, 0, 0.15) !important;
        // color: rgb(255, 255, 255)

    }
}


.btn-logo-color-bordered {
    border-color: var(--logo-color) !important;
    color: var(--logo-color) !important;

    &:hover, &:focus {
        background-color: var(--logo-color-toggled) !important;
        color: white !important;
    }
}


.btn-logo-color {
    background-color: var(--logo-color) !important;
    border-color: var(--logo-color) !important;
    color:white !important;

    &:hover, &:focus {
        background-color: var(--logo-color-toggled) !important;
        color:white !important; // override bootstraphover for btn
    }
}


.btn-main {
    background-color: var(--primary);
    border-color: var(--primary);
    color: black;

    &:hover, &:focus{
        background-color: var(--primary-toggled);
        color: black;
    }
}

.btn-plain {
    background-color: var(--majority-color);
    border-color: var(--border-color);
    color: var(--majority-contrast);

    &:hover, &:focus{
        background-color: var(--alternative);
        color: var(--majority-contrast);
    }
}


.btn-alternative {
    border-color: var(--alternative);
    background-color: var(--alternative);
    color: var(--majority-contrast);

    &:hover, &:focus{
        background-color: var(--alternative-toggled);
    }
}

.btn-success {
    border-color: var(--success);
    background-color: var(--success);
    color: var(--success-contrast);

    &:hover, &:focus{
        background-color: var(--success-toggled);
        color: var(--success-contrast);
    }
}

.btn-outline-success {
    border-color: var(--success);
    color: var(--success);

    &:hover, &:focus{
        background-color: var(--success);
        color: var(--success-contrast);
    }
}


.btn-danger {
    border-color: var(--danger);
    background-color: var(--danger);
    color: var(--danger-contrast);

    &:hover, &:focus{
        background-color: var(--danger-toggled);
        color: var(--danger-contrast);
    }
}


.navigation-colors {
    color: var(--tertiary);
    text-decoration: none;

    transition: all 0.2s ease-out 0s;

    &:hover, &.active {
        background-color: var(--alternative);
        color: var(--majority-contrast);
    }
}
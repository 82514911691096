@import '../../../styles/_variables.scss';

#sidebar {
    position: -webkit-sticky;
    position: sticky;
}

@media (min-width: 768px) {
    #sidebar {
        display: flex;
        flex-direction: column;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
}

#sidebar-lg-main {
    flex: 1 0;
    overflow: hidden;
    position: relative;
}

#sidebar-lg-content {
    height: 100%;
    padding-top: 1rem;
}

.sidebar-lg-navlinks {
    li:not(:last-child) {
        margin-bottom: 0.5rem;
    }
}

.sideBar-navlink {
    font-weight: 500;
    padding: 1rem;
    
    flex: 1 1 auto;
    white-space: nowrap;
    display: flex;
    align-items: center;
    margin: 0;
}

#toc-modal {
    position: fixed;
    background-color: var(--majority-color);
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.05rem 1rem 0.3rem var(--shadow-color);
    height: 400px;
}

#sidebar-sm-content {
    background-color: var(--majority-color);
    box-shadow: 0 0rem 0.3rem 0.07rem var(--shadow-color);
    display: flex;

    li {
        flex: 1 0 auto;
    }
}
.sticky-content { //applies to column, which only gets scrolled past for the small view
    position: -webkit-sticky;
    position: sticky;
}

.fill-container {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.vertical-divider {
    height: 100%;
}

.mb-3rem-nlc:not(:last-child) {
    margin-bottom: 3rem;
}

.mb-2rem-nlc:not(:last-child) {
    margin-bottom: 2rem;
}




@mixin form {
    width: 100%;
    margin: auto;
}

.small-form {
    max-width: 540px;
    @include form;
}

.medium-form {
    max-width: 680px;
    @include form;
}

.large-form {
    max-width: 820px;
    @include form;
}

.xlarge-form {
    max-width: 900px;
    @include form;
}

@mixin left-form {
    width: 100%;
    margin-right: auto;
}

.medium-left-form {
    max-width: 700px;
    @include left-form;
}


.not-expand-with-children {
    min-width: 0;
}

.sidebar-sibling {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
    width: 100%;
    flex: 0 0 auto;
}

@media (min-width: 768px) {
    .sidebar-sibling {
        padding-left: 2rem;
        padding-right: 2rem;
        flex: 1 0;
        min-width: 0;
    }
}

.bottom-right-overlay {
    position: fixed;
    bottom: 3%;
    right: 5%;
}


// z-indices: keep them all together to easily remember interactions

.table-sticky-header {

    // id header cell
    thead th:first-child {
        z-index: 4 !important;
    }

    // Id column
    tbody th {
        z-index: 1;
    }
}

.lowest-z {
    z-index: 2;
}

.second-lowest-z {
    z-index: 3;
}

.third-lowest-z {
    z-index: 4;
}

.small-toc {
    z-index: 10;
}

.fourth-highest-z {
    z-index: 997;
}

.third-highest-z {
    z-index: 998;
}

.second-highest-z {
    z-index: 999;
}

.highest-z {
    z-index: 1000;
}


@mixin image-size {
    width: 100%;
    height: 100%; // maybe be necessary instead of 'auto' to avoid vertical stretching on safari browser
}

@mixin landscape-image {
    @include image-size;
    // border: 1px dashed var(--tertiary);
    border-radius: 0.15rem;
    max-width: 1000px; // just so that images don't get wider than their export size: any higher value. for screenshots, 3000px to minimize partial voluming
}

@mixin portrait-image {
    @include image-size;
    max-width: 400px; // otherwise height becomes too large
}


@mixin portrait-image-sm {
    @include image-size;
    max-width: 300px; // otherwise height becomes too large
}


.landscape-image {
    @include landscape-image;
}

.portrait-image {
    @include portrait-image;
}

.landing-page-image {
    @include image-size;
    border-radius: 0.15rem;
    max-width: 1000px; // just so that images don't get wider than their export size: any higher value. for screenshots, 3000px to minimize partial voluming
}

.rotate-icon {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
@import './variables';

.gradsun-banner {
    // background-image: linear-gradient(to top left, #ffd35c, #ffdad0);
    background-image: var(--banner-gradient);
    min-height: 50vh; // because people first notice the 60% point of vh.
    position: relative;
}

.plain-banner {
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 2px solid #dedede !important;
    border-top: 2px solid #dedede !important;
}

// Shadows

// Box Shadow

// TODO: move these to mixins in _shadows
.shadow-no-offset {
    box-shadow: 0 0rem 0.1rem 0.1rem rgba(0, 0, 0, 0.075) !important
}

.shadow-dark-no-offset {
    box-shadow: 0 0rem 0.4rem 0.1rem rgba(0, 0, 0, 0.10) !important
}

.shadow-low-offset {
    box-shadow: 0 0.05rem 0.1rem 0.1rem rgba(0, 0, 0, 0.075) !important
}



// Divider between buttons and forms

.divider {
    border-top: 1px solid #d9dadc;
    display: block;
    line-height: 1px;
    margin: 30px 0;
    position: relative;
    text-align: center;

    .divider-title {
        background: var(--majority-color);
        font-size: 12px;
        letter-spacing: 1px;
        padding: 0 20px;
        text-transform: uppercase;
    }
}

.scrollbar-nice {
    overflow: auto;
    
    /* width */
    &::-webkit-scrollbar {
        width: 0.4rem;
        height: 0.4rem;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background-color: var(--alternative);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background-color: var(--tertiary);
        border-radius: 0.3rem;
        // height: 2rem !important;
        width: 10rem;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background-color: var(--majority-contrast);
    }
}


.scrollbar-obvious {
    

    /* width */
    &::-webkit-scrollbar {
        width: 1rem;
        height: 1.5rem;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background-color: var(--alternative);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background-color: var(--primary-toggled);
        border-radius: 0.3rem;
        width: 10rem;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background-color: var(--majority-contrast);
    }
}


.glassy {
    backdrop-filter: blur(6px);
}

.cursor-pointer {
    cursor: pointer;
}


.scores-table {
    width: 60%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
// first modify bootstrap classes
.form-control { //modifying bootstrap class
    font-size: 1em;
}

// Strength bar

// Password strength meter color for the different levels
$strength-colors: (darkred, orangered, orange, yellowgreen, green);

// Gap width between strength meter bars
$strength-gap: 0.5em;


.strength-meter { //background and container with gaps on top of fill
    position: relative;
    height: 0.5em;
    background: #DDD;
    margin: 0.5em 0;
    border-radius: 0.25em;
  
    // Dynamically create the gap effect with broders of empty boxes
    &:before,
    &:after {
        content: '';
        height: inherit;
        background: transparent;
        display: block;
        border-color: #FFF;
        border-style: solid;
        border-width: 0 $strength-gap 0;
        position: absolute;
        width: calc(20% + #{$strength-gap});
        z-index: 10; //gaps above fill
    }
  
    // Dynamically create the gap effect
    &:before {
        left: calc(20% - #{($strength-gap / 2)});
    }
  
    // Dynamically create the gap effect
    &:after {
        right: calc(20% - #{($strength-gap / 2)});
    }

    .success-pointer {
        right: calc(50% - #{($strength-gap)});
        top: 0.5em;
        color: green;
    }

    .fail-pointer {
        right: calc(50% - #{($strength-gap)});
        top: 0.5em;
        color: red;
    }
}
  
.strength-meter-fill { //color and transition
    background: transparent;
    height: inherit;
    position: absolute;
    width: 0;
    border-radius: inherit;
    transition: width 0.5s ease-in-out, background 0.25s;
  
    // Dynamically generate strength meter color styles
    @for $i from 1 through 5 {
        &[data-strength='#{$i - 1}'] {
            width: (20% * $i);
            background: nth($strength-colors, $i);
        }
    }
}
body {
  background-color: var(--majority-color);
  color: var(--majority-contrast);
  overscroll-behavior-x: none;
}

a {
  color: var(--link-color);

    &:hover, &:focus {
        color: rgb(0,0,0);
    }
}

.bg-majority {
  background-color: var(--majority-color) !important;
}

.bg-main {
  background-color: var(--primary) !important;
}

.bg-alternative {
  background-color: var(--alternative) !important;
}

.bg-alternative-toggled {
  background-color: var(--alternative-toggled) !important;
}

.bg-tertiary {
  background-color: var(--tertiary) !important;
}

.bg-success-custom {
  background-color: var(--success) !important;
}

.badge-one-colors {
  background-color: #6c757d;
  color: rgb(255,255,255);
}

.badge-two-colors {
  background-color: rgb(200,200,200);
  color: rgb(0,0,0);
}

.border-main {
  border-color: var(--primary) !important;
}


.transparent {
  color: var(--transparent) !important;
  * {
    color: var(--transparent) !important;
  }
}

// Responsive styles

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  
      .ovf#{$infix}-auto {
        overflow: auto;
      }
      
      .ovf#{$infix}-hidden {
        overflow: hidden;
      }
    }
}
  


// .form-check.form-box .form-check-input:checked[type="checkbox"] {
//   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='rgb(100,100,100)' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
// }


.bg-overlay {
  background-color: var(--transparent);
}


// alerts
.top-border-success, .top-border-danger, .top-border-info {
  border-radius: 0 0 0.25rem 0.25rem !important;
}


.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-top: 4px solid hsl(153, 31, 50);
}


.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-top: 4px solid #086e83;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-top: 4px solid hsl(354, 70, 50);
}
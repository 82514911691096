$first-column-width: 12rem;
$top-row-padding-horizontal: 0rem;


@mixin first-column {
    position: -webkit-sticky;
    position: sticky;
    left: 0px;
    background-image: none;
    width: $first-column-width;
    max-width: $first-column-width;
}

@mixin top-row-padding {
    padding-top: $top-row-padding-horizontal;
    padding-bottom: $top-row-padding-horizontal;
    padding-right: $top-row-padding-horizontal;
    padding-left: $top-row-padding-horizontal;
    // box-shadow: 0px -10px 10px var(--primary); // to hide table when scrolled above headers
}

@mixin top-row {
    @include top-row-padding;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    background-color: var(--majority-color);
}


.table-sticky-header {
    &.table-responsive {
        overflow-y: auto;
    }
    // * Table headers
    // id header cell
    thead th:first-child {
        @include first-column;
        @include top-row;
    }
    thead th:not(:first-child) {
        @include top-row;
    }

    // Id column
    tbody th {
        @include first-column;
        padding: 0;
        // box-shadow: -10px 0px 2px #ebebeb; // to hide table when scrolled beyond id column
    }

    // All cells
    td > div {
        overflow-y: auto;
        overflow-x: hidden; // needed to prevent scroll when it does not fit into td
    }
}


table {
    color: var(--majority-contrast) !important;

    ul {
        padding-left: 1.2rem;
    }
}

.table-hover tbody tr:hover {
    color: var(--majority-contrast);
}

.table-striped tbody tr:nth-of-type(odd) {
    color: var(--majority-contrast);
}


// borders
.table-unbordered > :not(caption) > * > * {
    border-color: #a9a9a9;
    border: none;
    border-width: 0px;
}

.border-tertiary {
    border-color: var(--tertiary) !important;
}


tr#current-user-id {
    td {
        .badge.text-in-bg {
            color: rgb(33, 37, 41) !important;
        }
    }
}
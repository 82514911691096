@-webkit-keyframes pulsate {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes pulsate {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes pulsate {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

@mixin pulsating-angled {
    background-size: 1000% 1000%;

    -webkit-animation: pulsate 3s ease infinite alternate;
    -moz-animation: pulsate 3s ease infinite alternate;
    animation: pulsate 3s ease infinite alternate;
}

.pulsating-angled {
    background: var(--pulsating-gradient);
    @include pulsating-angled;
}

.pulsating-dark-angled {
    background: var(--pulsating-dark-gradient);
    @include pulsating-angled;
}


// vertical ones need different keyframes
@-webkit-keyframes pulsate-vertical {
    0%{background-position:45% 0%}
    50%{background-position:56% 100%}
    100%{background-position:45% 0%}
}
@-moz-keyframes pulsate-vertical {
    0%{background-position:45% 0%}
    50%{background-position:56% 100%}
    100%{background-position:45% 0%}
}
@keyframes pulsate-vertical {
    0%{background-position:45% 0%}
    50%{background-position:56% 100%}
    100%{background-position:45% 0%}
}


@mixin pulsating-vertical {
    background-size: 300% 300%;
    -webkit-animation: pulsate-vertical 3s ease infinite alternate;
    -moz-animation: pulsate-vertical 3s ease infinite alternate;
    animation: pulsate-vertical 3s ease infinite alternate;
}

.pulsating-vertical {
    background: var(--pulsating-gradient);
    @include pulsating-vertical;
}


.pulsating-dark-vertical {
    background: var(--pulsating-dark-gradient);
    @include pulsating-vertical;
}


// toggleable transitions
.toggle-expand {
    transition-property: max-height;
    transition-duration: 0.2s;
    transition-timing-function: linear;

    max-height: 0px;
    overflow: hidden;
}

.toggle-rotate {
    transition-property: transform;
    transition-duration: 0.05s;
    transition-timing-function: linear;

    transform: rotate(0deg);
    overflow: hidden;

    &.rotated {
        transform: rotate(-90deg);
    }
}
@import 'styles/_variables.scss'; // bs variables need to be specified before bs
// bootstrap
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/variables-dark';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/utilities';

// Layout & components
@import '~bootstrap/scss/containers.scss';
@import '~bootstrap/scss/reboot.scss';
@import '~bootstrap/scss/type.scss';
@import '~bootstrap/scss/grid.scss';
@import '~bootstrap/scss/tables.scss';
@import '~bootstrap/scss/forms.scss';
@import '~bootstrap/scss/nav.scss';
@import '~bootstrap/scss/navbar.scss';
@import '~bootstrap/scss/pagination.scss';
@import '~bootstrap/scss/badge.scss';
@import '~bootstrap/scss/progress.scss';
@import '~bootstrap/scss/buttons.scss';

@import '~bootstrap/scss/helpers.scss';

// Utilities
@import '~bootstrap/scss/utilities/api.scss';


// custom
@import 'styles/_buttons.scss';
@import 'styles/_misc.scss';
@import 'styles/_position_and_size.scss';
@import 'styles/_style.scss';
@import 'styles/_tables.scss';
@import 'styles/_text.scss';
@import 'styles/_animations.scss';
@import 'styles/_exteriors.scss';
@import 'styles/_forms.scss';

// bg (background) refers to the main color (light in lightmode, dark in darkmode)
:root {
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    
    --majority-color: #f7f7f7;
    --majority-contrast: #212529;
    --majority-faded: #e7e7e7;

    --confidential: #dddddd;

    --transparent: rgba(0,0,0,0.5);
    --transparent-focus: rgba(0,0,0,0.3);
    --transparent-contrast: #f7f7f7;

    --link-color: #398fff;
    
    --primary: #FFDC7A;
    --primary-toggled: #ebc45a;
    --primary-text: #d8ab30;
    --primary-transparent: #ffce487d;
    --alternative: #ebebeb;
    --alternative-toggled: #dadada;
    --logo-color: #2E5BA3;
    --logo-color-toggled: #1b407c;
    --tertiary: #6c757d;
    --success: #198754 !important; // bg success
    --success-toggled: #277551;
    --success-contrast: white;
    --danger: #dc3545; // bg danger
    --danger-toggled: #b92938;
    --danger-contrast: white;

    --banner-gradient: linear-gradient(to top left, #ffd35c, #ffdad0 75%, #fff5f5 92%);
    --pulsating-gradient: linear-gradient(300deg,#dadada, #f6f6f6, #dadada);
    --pulsating-dark-gradient: linear-gradient(300deg,#cecece, #e4e4e4, #cecece);

    --list-images: url("styles/icons/checkBlack.svg");

    --border-color: rgba(0, 0, 0, 0.12);
    --bs-border-color: var(--border-color);
    --bs-border-width: 1px;
    --bs-border-radius: 0.25rem;
    --bs-border-style: solid;
    --bs-border-radius-pill: 50rem;
    --input-color: #f7f7f7;
    --input-contrast: #212529;
    --shadow-color: rgba(0, 0, 0, 0.075);

    &.dark {
        --majority-color: #343434;
        --majority-contrast: #f7f7f7;
        --majority-faded: #4b4b4b;

        --confidential: #252525;

        --transparent: rgba(255,255,255,0.5);
        --transparent-focus: rgba(255,255,255,0.3);
        --transparent-contrast: #343434;
    
        --link-color: #398fff;
        
        --primary: #FFDC7A;
        --primary-toggled: #ebc45a;
        --alternative: #4c4e51;
        --alternative-toggled: #444546;
        --logo-color: #2E5BA3;
        --logo-color-toggled: #3d67ac;
        --tertiary: #85888b;
        --success: #48ce80 !important; // bg success
        --success-toggled: #67ffa6;
        --success-contrast: rgb(0, 0, 0);
        --danger: #ff4457; // bg danger
        --danger-toggled: #b92938;
        --danger-contrast: white;

        --banner-gradient: linear-gradient(to top left, #2a5ca2, #2e2e2e  92%);
        --pulsating-gradient: linear-gradient(300deg,#585858, #7c7c7c, #585858);
        --pulsating-dark-gradient: linear-gradient(300deg,#7c7c7c, #525252, #7c7c7c);

        --list-images: url("styles/icons/checkGradsun.svg");

        --border-color: rgba(255, 255, 255, 0.2);
        --shadow-color: rgba(255, 255, 255, 0.13);
    }
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

p {
    margin: 0;
}

.text-page {

    > * {
        > * {
            margin-top: 3rem;
            margin-bottom: 3rem;
            > * {
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
        }
    }
}

.list-bulleted {
    list-style: disc;
    padding: 0rem 0rem 0rem 2rem;
}
// if you want to modify bootstrap classes,
// you must import the style here. 
// This will only modify bootstrap in js
// where the scss is imported
@import 'components/forms_authentication/PasswordFieldWithStrength.scss';
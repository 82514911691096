@import './shadows.scss';


.exterior {
    @include shadow-low-offset;
    border-radius: 0.25rem;
    border: 1px solid var(--border-color);
}

.exterior-soft {
    @include shadow-low-offset;
    border-radius: 0.25rem;
}
@import '../../styles/_variables.scss';

.small-toc {
    background-color: rgb(255,255,255);
    margin-top: -0.5rem;
    padding: 1rem;
    overflow: scroll;
    max-height: 10rem;
}

.toc-title {
    font-size: 1.5rem;
}

@mixin toc-tracker {
    content: '';
    width: 0.17rem;
    height: 100%;
    position: absolute;
    left: 0rem;
}

#table-of-contents {

    height: 100%;

    .toc-content {
        font-size: 1rem;
        padding-left: 2rem;
        position: relative;
    }
    
    .toc-subcontent {
        font-size: 0.75rem;
        padding-left: 3rem;
        position: relative;
    }

    .toc-content, .toc-subcontent {
        a {
            color: var(--tertiary);

            &:hover, &:focus {
                color: var(--link-color);
            }

            &.link-danger {
                color: #dc3545 !important;
            }

            &.link-is-not-empty {
                color: var(--success);
            }
        }

        &.is-above-half > a {
            color: var(--link-color);
        }

        &.is-above-half::before {
            @include toc-tracker;
            background-color: var(--link-color);
        }
    
        &:not(.is-above-half)::before {
            @include toc-tracker;
            background-color: #cccccc;
        }
    }

}
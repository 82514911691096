
@font-face {
    font-family: 'AbsoluteEmpire';
    src: local('AbsoluteEmpire'), url(./fonts/AAbsoluteEmpire-EaXpg.ttf) format('truetype');
}
// Text-shadow
.text-shadow-for-overlay {
    text-shadow: 0px 0px 0.5rem rgb(0, 0, 0);
}

.text-shadow-thick {
    text-shadow: 1px 0px 7px rgb(247, 0, 255);
}


// headings
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}


// Text highlights

.linkify {
    color: var(--link-color);
}

.linkify-clickable {
    color: var(--link-color);

    &:hover, &:focus {
        color: rgb(0,0,0);
    }
}

.text-main {
    color: var(--primary-text);
}

// background being light in lightmode, dark in darkmode
.text-in-bg {
    color: var(--majority-contrast);
}


.text-tertiary {
    color: var(--tertiary);
}

.text-alternative-toggled {
    color: var(--alternative-toggled);
}

// solid being dark in lightode, light in darkmode
.text-in-solid {
    color: var(--majority-color);
}


.text-success-contrast {
    color: var(--success-contrast);
}

.text-danger {
    color: var(--danger) !important;
}

.text-danger-contrast {
    color: var(--danger-contrast);
}


$font-size-multiplier: 1.2;
// adjusting font-size for blog post. It looks big on medium.com
.font-size-long-page {
    font-size: 1rem * $font-size-multiplier;

    h1 {
        font-size: $h1-font-size * $font-size-multiplier;
    }
    h2 {
        font-size: $h2-font-size * $font-size-multiplier;
    }
    h3 {
        font-size: $h3-font-size * $font-size-multiplier;
    }
    h4 {
        font-size: $h4-font-size * $font-size-multiplier;
    }
    h5 {
        font-size: $h5-font-size * $font-size-multiplier;
    }
    h6 {
        font-size: $h6-font-size * $font-size-multiplier;
    }

    small {
        font-size: 0.8rem * $font-size-multiplier;
    }

}
@import '../../styles/_variables.scss';

.slider, .slider-bulk {
    position: absolute;
    height: 100%;
    top: 0;
}

$visible-slider-width: 1px;
$slider-width: 20px;

.slider {
    width: $visible-slider-width;
    right: ($slider-width + $visible-slider-width)/2;
}


.slider-bulk {
    background-color: transparent;
    width: $slider-width;
    right: (-$slider-width/2);
    cursor: w-resize !important;
    z-index: 20;
}

input {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--input-color);
    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
    font-family: var(--font-family);
}

input:focus {
    box-shadow: 0 0 0 0.25rem var(--primary-transparent);
    outline: none;
}

.switch {
    position: relative;
    display: flex;
    align-items: center;

    .switch-input-container {
        position: relative;
        height: 1.5rem;
        width: 3.3rem;
        display: inline-flex;
        align-items: center;

        .switch-input {
            margin: 0;
            width: 100%;
            height: 100%;
            border-radius: 1.75em;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: left center;
            transition: background-position 0.15s ease-in-out;

            &:checked {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
                background-position: right center;
                background-color: var(--primary);
            }
        }

        .switch-icon {
            position: absolute;
            display: flex;
            color: var(--input-contrast);
        }
        
    }

    .switch-label {
        height: 100%;
        white-space: nowrap;
        display: flex;
        align-items: center;
        margin-left: 0.5rem;
    }    
}